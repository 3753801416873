<template>
  <div>
    <Hero id="hero" />
    <about-us id="about-us" />
    <AppComparison id="app-comparison" />
    <AppsFeatures id="app-features" />
    <HowItWorks id="how-it-works" />
    <CTA id="cta" />
    <ContactUs id="contact-us" />
  </div>
</template>

<script>
import Hero from "./home/Hero";
import AboutUs from "./home/AboutUs";
import AppsFeatures from "./home/AppsFeatures";
import AppComparison from "./home/AppComparison";
import HowItWorks from "./home/HowItWorks";
import CTA from "./home/CTA";
import ContactUs from "./home/ContactUs";

export default {
  name: "home",
  metaInfo: {
    title: "Home Page",
    meta: [{ vmid: "description", name: "description", content: "Home Page" }],
  },
  components: {
    Hero,
    AboutUs,
    AppComparison,
    AppsFeatures,
    HowItWorks,
    CTA,
    ContactUs,
  },
  data() {
    return {
      offsetTop: 0,
    };
  },
  watch: {
    offsetTop(val) {
      this.onScrollCallback();
    },
  },
  created() {
    // Create scroll event listener
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    const hash = location.hash;
    if (hash) {
      this.$scrollTo(hash);
    }
  },
  methods: {
    handleScroll(event) {
      // Any code to be executed when the window is scrolled
      this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
    },
    onScrollCallback() {
      let items = document.querySelectorAll(".video");

      let isElementInViewport = false;
      for (var i = 0; i < items.length; i++) {
        if (this.isElementInViewport(items[i])) {
          isElementInViewport = true;
        } else {
          isElementInViewport = false;
        }
      }

      if (isElementInViewport) {
        this.$store.dispatch("scroll/playVideoAction", true);
      } else {
        this.$store.dispatch("scroll/playVideoAction", false);
      }
    },
    isElementInViewport(el) {
      var rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      );
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
