<template>
  <section class="section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-sm-12 text-center col-md-6 py-5">
          <img src="../../assets/images/optimized/ayun-apps.png" alt="Phone Screen" class="phone" />
        </div>
        <div class="col-md-5">
          <h2>Download app to enjoy more!</h2>
          <p>
            TAWItech Inc. was established to develop applications that could
            solve challenges in the supply chain. TAWItech's first product,
            Ayun!, is a revolutionary cloud-based asset monitoring platform
            capable of monitoring the movement of any asset within
            interconnected supply chains.
          </p>
          <div class="links d-flex justify-content-center">
            <img src="../../assets/images/optimized/Playstore_Button.svg" class="downloadable-link"
              style="margin-right:30px" @click="downloadApp" alt="GooglePlayStore" />
            <img src="../../assets/images/optimized/AppGallery_Button.svg" class="downloadable-link"
              @click="downloadAppGallery" alt="ApppGallery" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "cta",
  methods: {
    downloadApp() {
      window.open(
        "https://play.google.com/store/apps/dev?id=5340837496104612381", // change this soon
        "_blank"
      );
    },
    downloadAppGallery() {
      window.open(
        "https://appgallery.huawei.com/tab/appdetailCommon%7CC103713569%7Cautomore%7Cdoublecolumncardwithstar%7C903547", // change this soon
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  background-color: #eaf1fc;

  h2 {
    font-weight: bolder;
    font-size: 25px;
    position: relative;
    padding: 10px 0 10px;
    margin: 0 0 50px;
    text-align: center;
    color: #122c91;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: 160px;
      width: 90%;
      height: 2px;
      background: #f18f01;
    }
  }

  p {
    font-size: 16px;
    text-align: justify;
  }

  .links {
    margin: 57px 0 0;

    .downloadable-link {
      max-width: 180px;
      width: 48%;
    }
  }

  .phone {
    max-width: 545.57px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  h2 {
    margin-top: -70px !important;
  }

  p {
    font-size: 14px;
    margin: 20px;
    margin-top: -30px;
  }

  .phone {
    width: 350px !important;
    margin-bottom: -80px;
    margin-top: -300px;
  }

  .downloadable-link {
    height: 40px;
    margin: -30px;
  }
}
</style>
