<template>
  <section class="section-shaped background-image bg-secondary mt-5 mb-0 pt-5">
    <div class="container shape-container">
      <div class="col px-0">
        <div class="row align-items-center">
          <div class="col-md-6 pb-5 first">
            <h3 class="display-3" style="color: #122c91 !important" id="md-title">
              Asset Tracking App <br />
              Best for You
            </h3>
            <p style="color: #f18f01 !important">
              <b> Simple. Transparent. Automated.</b>
            </p>
            <div class="row mb-0">
              <div class="col-sm-12 col-md-12 pl-0 download-container">
                <img src="../../assets/images/optimized/Playstore_Button.svg"
                  class="downloadable-link my-1 download-button1" @click="downloadApp" height="50px"
                  style="padding: 0 15px" alt="GooglePlay" />
                <img src="../../assets/images/optimized/AppGallery_Button.svg"
                  class="downloadable-link my-1 download-button2" @click="downloadAppGallery" height="50px"
                  style="padding: 0 15px" alt="AppGallery" />
              </div>
            </div>
          </div>
          <div class="col-sm-12 text-center col-md-6 text-right second">
            <div class="banner-image-container">
              <img src="/img/common/optimized/Hero_Asset_1.svg" class="hero-image" alt="Ayun!" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "hero",
  methods: {
    downloadApp() {
      window.open(
        "https://play.google.com/store/apps/details?id=com.tawitech.ayun", // change this soon
        "_blank"
      );
    },
    downloadAppGallery() {
      window.open(
        "https://appgallery.huawei.com/#/app/C103708333", // change this soon
        "_blank"
      );
    },
  },
};
</script>

<style scoped>
h3,
.display-3.ayun {
  color: #260041 !important;
}

p {
  color: #260041 !important;
}

.background-image {
  background: linear-gradient(180deg,
      #dee9f9 -17.33%,
      rgba(222, 233, 249, 0) 75.91%),
    #ffffff;
}

.hero-image {
  width: 100%;
  padding: 0 15px;
  margin-top: -40px;
}

@media (max-width: 768px) {
  .first {
    order: 2;
  }

  .second {
    order: 1;
  }

  h3 {
    font-size: 35px;
    text-align: center;
  }

  p {
    font-size: 20px;
    padding: 20px;
    margin-top: -20px;
    text-align: center;
  }

  .hero-image {
    width: 70%;
  }
}

@media only screen and (max-width: 990px) {
  h3 {
    font-size: 30px;
  }

  p {
    font-size: 17px;
  }

  .download-container {
    text-align: center;
  }

  .hero-image {
    width: 100%;
  }
}

@media only screen and (max-width: 750px) and (min-width: 250px) {
  .download-button1 {
    height: 40px;
    margin-left: -30px;
  }

  .download-button2 {
    height: 40px;
    margin-right: -30px;
  }

  .download-container {
    text-align: center;
  }
}
</style>
