import { db } from "@/config/firebase";

async function createInquiry(inquiry) {
    let result = {
        isSuccess: false,
        inquiry: inquiry,
    }
    try {
        let inquiryRef = await db.collection("inquiries").add(inquiry);
        result.inquiry.id = inquiryRef.id;
        result.isSuccess = true;
    } catch (error) {
        result.isSuccess = false;
    }

    return result;
}

export default {
    createInquiry
}