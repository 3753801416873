<template>
  <section class="section">
    <div class="container">
      <div class="row justify-content-center text-center mb-sm">
        <h3>
          <b> How do we help our customers in the digital era?</b>
        </h3>
      </div>
      <br />
      <div class="row justify-content-center mb-md">
        <div class="col-sm-12 text-center col-md-6">
          <img src="../../assets/images/optimized/ayun-ab.svg" class="image" alt="Ayun! AB Logo" />
        </div>
        <div class="col-md-6">
          <h2 class="display-3 after">Ayun! Asset-Based</h2>
          <p>
            Automate the tracking of your assets through Ayun! Asset-Based, an
            asset monitoring system that uses QR CODES and CLOUD TECHNOLOGY to
            transfer and track the accountability of Returnable Packaging
            Equipment (RPE) within the supply chain. Ayun! AB has fixed asset
            monitoring in multiple locations, both online and offline.
          </p>
          <router-link
            to="/ayun-asset-based"
            class="card-link mt-auto link"
            data-toggle="dropdown"
            role="button"
          >
            Learn more
            <span><i class="fa fa-arrow-right"></i></span>
          </router-link>
        </div>
      </div>
      <div class="row justify-content-center mb-md">
        <div class=" col-md-6 first">
          <h2 class="display-3 after">Ayun! Quantity-Based</h2>
          <p>
            Automate the tracking of your fast-moving assets through Ayun!
            Quantity-Based, an asset monitoring system that is capable of
            monitoring multiple returnable moving assets and uses SECURED CLOUD
            TECHNOLOGY to transfer and track the accountability of Returnable
            Packaging Equipment (RPE) within the supply chain.
          </p>
          <router-link
            to="/ayun-quantity-based"
            class="card-link mt-auto link"
            data-toggle="dropdown"
            role="button"
          >
            Learn more
            <span><i class="fa fa-arrow-right"></i></span>
          </router-link>
        </div>
        <div class="col-sm-12 text-center col-md-6 second">
          <img src="../../assets/images/optimized/ayun-qb.svg" class="image" alt="Ayun! QB Logo" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "apps-features",
};
</script>

<style lang="scss" scoped>
.section {
  background-color: #eaf1fc;
  h2 {
    color: #122b91;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: 160px;
      width: 90%;
      height: 2px;
      background: #f18f01;
    }
  }
  h3 {
    color: #122b91;
    font-size: 35px;
  }
  p {
    font-size: 16px;
    text-align: justify;
    margin-top: -20px;
  }
  .link {
    color: #f18f01;
  }
  .button {
    color: #f18f01;
  }
  .card-center {
    margin-top: -30%;
  }
  .image {
    height: 600;
    max-width: 796.08px;
    padding: 55px 15px 60px;
    margin-top: -150px;
  }
  .card {
    position: relative;
    max-width: 450px;
    width: 100% !important;
    min-height: 525px !important;
    padding: 48px;
    box-sizing: border-box;
    margin-top: 100px;
    margin-left: auto;
    box-shadow: 5px 20px 50px rgba(74, 153, 211, 0.2);
    border-radius: 10px;

    .card-link {
      color: #f18f01 !important;
      margin-top: auto;
      span {
        margin-left: 36px;
      }
      &:hover {
        color: #f18f01 !important;
      }
    }
  }
}

h2 {
  font-weight: bolder;
  font-size: 40px;
  position: relative;
  padding: 10px 0 10px;
  margin: 0 0 50px;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 160px;
    width: 90%;
    height: 2px;
    background: #f18f01;
  }
}
@media (max-width: 990px) {
  .first {
    order: 2;
  }
  .second {
    order: 1;
  }
  h3 {
    font-size: 30px !important;
    margin: 20px;
  }
  h2 {
    font-size: 25px !important;
  }
  p {
    font-size: 14px !important;
    margin: 20px;
  }
  .link {
    font-size: 14px !important;
    margin-left: 20px;
  }
  .image {
    width: 400px;
    margin-bottom: -70px;
  }
}
@media only screen and (max-width: 860px) and (min-width: 250px) {
  .image {
    width: 300px;
  }
}
</style>
