var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section section-ayun"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"panel"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('loading',{attrs:{"active":_vm.isLoading,"loader":"spinner","color":"#20A8D8","is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('h6',{staticClass:"mb-1"},[_vm._v("Leave us a message")]),_c('ValidationObserver',{ref:"form"},[_c('ValidationProvider',{attrs:{"name":"Company Name","rules":{
              required: true,
              regex: _vm.companyNameRegex
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{staticClass:"mt-4",attrs:{"alternative":"","placeholder":"Company","error":errors[0]},model:{value:(_vm.form.companyName),callback:function ($$v) {_vm.$set(_vm.form, "companyName", $$v)},expression:"form.companyName"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Full Name","rules":{
              required: true,
              regex: _vm.fullNameRegex
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"alternative":"","placeholder":"Full Name","error":errors[0]},model:{value:(_vm.form.fullName),callback:function ($$v) {_vm.$set(_vm.form, "fullName", $$v)},expression:"form.fullName"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Contact No","rules":{
              required: true,
              regex: _vm.contactNoRegex
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"alternative":"","placeholder":"+63xxxxxxxxxxx","error":errors[0]},model:{value:(_vm.form.phoneNumber),callback:function ($$v) {_vm.$set(_vm.form, "phoneNumber", $$v)},expression:"form.phoneNumber"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Email Address","rules":{
              required: true,
              regex: _vm.emailRegex
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"alternative":"","placeholder":"Email Address","error":errors[0]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}])}),_c('base-input',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"name":"Message","rules":{
                required: true,
                regex: _vm.messageRegex
              }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.message),expression:"form.message"}],staticClass:"form-control form-control-alternative",attrs:{"name":"name","rows":"4","cols":"80","placeholder":"Type a message..."},domProps:{"value":(_vm.form.message)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "message", $event.target.value)}}}),_vm._v(" "),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('base-button',{attrs:{"type":"elog-primary","round":"","block":"","size":"lg"},on:{"click":_vm.onSubmit}},[_vm._v("Send Message")])],1),_c('div',{staticClass:"col-md-6 mb-3 mt-5 pl-5"},[_c('div',[_c('ul',{staticClass:"list-unstyled"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('li',{staticClass:"listed-info"},[_c('div',{staticClass:"map"},[_c('iframe',{staticClass:"google-map",attrs:{"src":_vm.googleMapLink,"allowfullscreen":"","loading":"lazy","title":"TAWItech Address"}})])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center text-center mb-sm"},[_c('div',{staticClass:"col-lg-12"},[_c('h3',{staticClass:"display-3 get-in-touch"},[_vm._v("Get In Touch")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"listed-info"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fa fa-map-marker icons"}),_c('h6',{staticClass:"mb-0"},[_vm._v(" Pulo-Diezmo Road, Cabuyao Laguna, Philippines, 4025 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"listed-info"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fa fa-phone icons"}),_c('h6',{staticClass:"mb-0"},[_vm._v("0917 111 8008")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"listed-info"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fa fa-envelope icons"}),_c('h6',{staticClass:"mb-0"},[_vm._v("support@tawitech.ph")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"listed-info"},[_c('div',{staticClass:"social-media d-flex"},[_c('a',{attrs:{"href":"https://www.youtube.com/channel/UC0QzEnq-E5kQLFveXynVdUg","target":"_blank"}},[_c('i',{staticClass:"fa fa-youtube-play"})]),_c('a',{attrs:{"href":"https://www.instagram.com/ayun_ph/","target":"_blank"}},[_c('i',{staticClass:"fa fa-instagram"})]),_c('a',{attrs:{"href":"https://www.facebook.com/ayunph","target":"_blank"}},[_c('i',{staticClass:"fa fa-facebook"})])])])
}]

export { render, staticRenderFns }